//ハンバーガーメニューの開閉
function openHum() {
    const menuBtn = document.getElementById('menu_btn');
    const menuDisplay = document.getElementById('menu_display');

    menuBtn.addEventListener('click', () => {
        menuDisplay.classList.toggle('active');
        menuBtn.classList.toggle('active');
    });
}
//ハンバーガー中のcontentとかの開閉
//設計変えたせいで多分色々必要なし(openだけいると思う)
function openDetail() {
    /*ボタン*/
    const contentDetail = document.getElementById('content_detail');
    const ourDetail = document.getElementById('our_detail');
    /*ボタン押して開いた先のやつ*/
    const contentNav = document.getElementById('content_nav');
    const ourNav = document.getElementById('our_nav');
    /*状態によって場所変わるやつ*/
    const menuOurself = document.getElementById('menu_ourself');
    const snsWrapper = document.getElementById('sns_wrapper');

    /*一旦全部リセットして、クラス名を追加するだけにする*/
    function resetState() {
        ourNav.classList.remove('our_open', 'content_open', 'full_open');
        menuOurself.classList.remove('our_open', 'content_open', 'full_open');
        snsWrapper.classList.remove('our_open', 'content_open', 'full_open');
    }

    /*CONTENTSとOURSELFがそれぞれ押されたタイミングで動く。冗長すぎる*/

    contentDetail.addEventListener('click', () => {
        contentNav.classList.toggle('open');
        contentDetail.classList.toggle('open');

        const contentOpen = contentNav.classList.contains('open');
        const ourOpen = ourNav.classList.contains('open');

        if (contentOpen == true && ourOpen == true) {
            resetState();
            menuOurself.classList.toggle('full_open');
            ourNav.classList.toggle('full_open');
            snsWrapper.classList.toggle('full_open');
        } else if (contentOpen == true && ourOpen == false) {
            resetState();
            menuOurself.classList.toggle('content_open');
            ourNav.classList.toggle('content_open');
            snsWrapper.classList.toggle('content_open');
        } else if (contentOpen == false && ourOpen == true) {
            resetState();
            ourNav.classList.toggle('our_open');
            snsWrapper.classList.toggle('our_open');
        } else {
            resetState();
        }
    });

    ourDetail.addEventListener('click', () => {
        ourNav.classList.toggle('open');
        ourDetail.classList.toggle('open');

        const contentOpen = contentNav.classList.contains('open');
        const ourOpen = ourNav.classList.contains('open');

        if (contentOpen == true && ourOpen == true) {
            resetState();
            menuOurself.classList.toggle('full_open');
            ourNav.classList.toggle('full_open');
            snsWrapper.classList.toggle('full_open');
        } else if (contentOpen == true && ourOpen == false) {
            resetState();
            menuOurself.classList.toggle('content_open');
            ourNav.classList.toggle('content_open');
            snsWrapper.classList.toggle('content_open');
        } else if (contentOpen == false && ourOpen == true) {
            resetState();
            ourNav.classList.toggle('our_open');
            snsWrapper.classList.toggle('our_open');
        } else {
            resetState();
        }
    });
}
export default {
    openHum,
    openDetail,
}
