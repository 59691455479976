<template>
  <div id="wrapper">
      <header id='gn' class="clearfix">
    <!-- <link rel="stylesheet" href="https://weebee1212.com/common/css/includer.css"> -->
    <div class="header_wrapper" id="header_mobile gnHome">
        <nav id="menu_display">
            <div class="nav_wrapper">
                <div class="menu_contents">
                    <div class="menu_overview">
                        <h1>CONTENTS</h1>
                        <h2>コンテンツ</h2>
                    </div>
                    <div class="btn_pulldown" id="content_detail">
                        <span></span>
                    </div>
                    <ul id="content_nav">
                        <a href="https://weebee1212.com/interview/">
                            <li>情熱取材</li>
                        </a>
                        <a href="https://weebee1212.com/snap/">
                            <li>SNAP×SNAP</li>
                        </a>
                        <a href="https://weebee1212.com/omotekou_new/">
                            <li>オモテコウ</li>
                        </a>
                        <a href="https://weebee1212.com/photo_bijo/">
                            <li>美男美女</li>
                        </a>
                        <a href="https://weebee1212.com/shintame/">
                            <li>WeeBlog</li>
                        </a>
                        <a href="https://weebee1212.com/mov_dantai/front/">
                            <li>動く団体図鑑</li>
                        </a>
                        <a href="https://misenavi.weebee1212.com/">
                            <li>ミセナビ</li>
                        </a>
                        <a href="https://weebee1212.com/dantai/front">
                            <li>団体図鑑</li>
                        </a>
                        <a href="https://weebee1212.com/shinkare/">
                            <li>シンカレ</li>
                        </a>
                        <a href="https://weebee1212.com/special_content/">
                            <li>特別コンテンツ</li>
                        </a>
                    </ul>
                </div>
                <div id="menu_ourself">
                    <div class="menu_overview">
                        <h1>OURSELF</h1>
                        <h2>運営団体</h2>
                    </div>
                    <div class="btn_pulldown" id="our_detail">
                        <span></span>
                    </div>
                    <ul id="our_nav">
                        <a href="https://weebee1212.com/aboutKooBee/front/contents_ourselves.php">
                            <li>神戸大学KooBee</li>
                        </a>
                        <a href="https://weebee1212.com/aboutKooBee/front/contents_koobee.php">
                            <li>KooBee</li>
                        </a>
                        <a href="https://weebee1212.com/aboutKooBee/front/contents_weebee.php">
                            <li>WeeBee</li>
                        </a>
                        <a href="https://weebee1212.com/aboutKooBee/front/contents_moovee.php">
                            <li>MooVee</li>
                        </a>
                    </ul>
                </div>
                <div id="sns_wrapper">
                    <div class="menu_twitter link_sns">
                        <img src="https://weebee1212.com/common/images/sp/twitter_hum.svg" alt="">
                        <ul>
                            <a href="https://twitter.com/koobee_kobe_u?s=21">
                                <li>@koobee_kobe_u</li>
                            </a>
                            <a href="https://twitter.com/koobee_weebee?s=21">
                                <li>@koobee_weebee</li>
                            </a>
                            <a href="https://twitter.com/koobeekoopoon?s=21">
                                <li>@KooBeeKooPoon</li>
                            </a>
                        </ul>
                    </div>
                    <div class="menu_instagram link_sns">
                        <img src="https://weebee1212.com/common/images/sp/insta_hum.svg" alt="">
                        <ul>
                            <a href="https://instagram.com/koobee_gram?igshid=1eu1fe93pnu2p">
                                <li>@koobee_gram</li>
                            </a>
                            <a href="https://instagram.com/misenavi_gram?igshid=1ik7gi1vr7l23">
                                <li>@misenavi_gram</li>
                            </a>
                            <a href="https://instagram.com/sanglier_fromkoobee?igshid=1w5qjlni857vg">
                                <li class="sanglier">@sanglier_fromkoobee</li>
                            </a>
                        </ul>
                    </div>
                    <div class="menu_youtube link_sns">
                        <img src="https://weebee1212.com/common/images/sp/youtube_hum.svg" alt="">
                        <ul>
                            <a href="https://www.youtube.com/channel/UCvvqZLvHHsNq_XuBABQ_r9Q">
                                <li>KooBeeTube</li>
                            </a>
                            <a href="https://www.youtube.com/channel/UCS8jnTgRWSk6mewJsHGjuPQ">
                                <li class="sanglier">SANGLIER サングリエ</li>
                            </a>
                        </ul>
                    </div>
                    <div class="menu_mail link_sns">
                        <img src="https://weebee1212.com/common/images/sp/mail_hum.svg" alt="">
                        <ul>
                            <a href="mailto:koobee.kobe.u@gmail.com">
                                <li>お問い合わせはこちら</li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <div class="header_logo">
            <a href="https://weebee1212.com/">
            <img src="https://weebee1212.com/common/images/sp/menu_logo.svg" alt="weebee">
            </a>
        </div>
        <div class="menu_header" id="menu_btn">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
  </header>
    <div 
    id="gnWrapper" 
    class="clearfix"
    ref="gnWrapper"
    >
      <router-view/>
    <Footer/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Footer from './components/footer.vue'
import HeaderJs from './js/menu'


export default {
  name: 'app',
  components : {
    Footer
  },
  data () {
    return {
      srcMenuImage: 'https://weebee1212.com/common/images/sp/menu.png',
      gnCoverWidth: '100%',
      gnCoverHeight: '100%',
      gnWrapperTop: '77px',
      status: 'none',
      showGnCover: false,
      showMenu: false,
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeWindow())
    HeaderJs.openHum();
    HeaderJs.openDetail();
  },
  methods: {
    resizeWindow () {
      const elGnWrapper = this.$refs.gnWrapper
      // const elGnHome = this.$refs.gnHome
      this.gnCoverWidth = String(window.innerWidth) + 'px'
      this.gnCoverHeight = String(elGnWrapper.clientHeight) + 'px'
    },
    switchMenu () {
      if(this.showMenu) {
        this.showMenu = false
        this.showGnCover = false
        this.srcMenuImage = 'https://weebee1212.com/common/images/sp/menu.png'
      } else {
        this.showMenu = true
        this.showGnCover = true
        this.srcMenuImage = 'https://weebee1212.com/common/images/sp/close.png'
      }
    }
  }
}
</script>

<style>
body{
  margin: 0;
  margin-top: -4.5%;
}
ul{
  padding-inline-start:0;
  margin: 0;
}

@keyframes show{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
#wrapper {
  margin: auto;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 375px;
}
#gnCover{
  background-color: white;
  opacity: 0.6;
  z-index: 9997;
  position: absolute;
  top: 70px;
  left: 0px;
}
#gnWrapper{
  position: relative;
  top: 77px;
  left: 0;
}

@charset "utf-8";
*, *:before, *:after {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}
/* gn */
#gn{
  top: 0%;
  position: fixed;
  width: 375px;
  background-color: white;
  z-index: 10000;
  animation: show 2s linear 0s;
}
#gnHome{
  position: relative;
  border-bottom: 1px solid #B7B7B7;
}
ul li {
        list-style: none;
    }
    
    header a:link,
    header a:visited,
    header a:hover,
    header a:active {
        text-decoration: none;
        color: black;
    }

    .header_wrapper {
        width: 337.5px;
        background-color: white;
        box-shadow: 4px 9px 17px -15px;
        margin: 20px auto;
        height: 48.75px;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 3.75px;
        right: 18.75px;
        left: 18.75px;
        z-index: 1000;
    }
    
    .header_logo {
        width: 25%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header_logo img {
        width: 100%;
        margin: 0 0vw 0 15px;
    }
    /* ハンバーガーメニュー関連 */
    
    .nav_wrapper {
        padding: 75px 18.75px;
        text-align: left;
    }
    
    .menu_contents{
      width: 337.5px;
    }
    .menu_header {
        position: relative;
        margin: 0 15px 0 0;
        width: 26px;
        height: 42%;
        cursor: pointer;
    }
    
    .menu_header span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #000;
        border-radius: 4px;
    }
    
    .menu,
    .menu_header span {
        display: inline-block;
        transition: all .5s;
        box-sizing: border-box;
    }
    
    .menu_header span:nth-of-type(1) {
        top: 0;
    }
    
    .menu_header span:nth-of-type(2) {
        top: 7.8px;
    }
    
    .menu_header span:nth-of-type(3) {
        bottom: 0;
    }
    /* バツに変わった時 */
    
    .active#menu_display {
        height: 100vh;
        overflow: scroll;
        left: auto;
    }
    
    .active#menu_btn span:nth-of-type(1) {
        -webkit-transform: translateY(7.8px) rotate(-45deg);
        transform: translateY(7.8px) rotate(-45deg);
    }
    
    .active#menu_btn span:nth-of-type(2) {
        opacity: 0;
    }
    
    .active#menu_btn span:nth-of-type(3) {
        -webkit-transform: translateY(-8.2px) rotate(45deg);
        transform: translateY(-8.2px) rotate(45deg);
    }
    
    #menu_display {
        position: fixed;
        background-color: white;
        transition: all 0.5s;
        width: 375px;
        height: 100vh;
        top: 0;
        left: 100%;
    }
    /* +-ボタン */
    
    .btn_pulldown span {
        display: inline-block;
        vertical-align: middle;
        color: #333;
        line-height: 1;
        width: 2em;
        height: 0.2em;
        background: currentColor;
        border-radius: 0.1em;
        position: relative;
    }
    
    .btn_pulldown span::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 3px;
        background: inherit;
        border-radius: inherit;
        transform: rotate(90deg);
    }
    
    .open.btn_pulldown span::before {
        transform: rotate(0deg);
    }
    
    .menu_overview {
        width: 236px;
        padding-left: 40px;
    }
    
    .btn_pulldown {
        width: 50px;
    }
    
    .menu_overview,
    .btn_pulldown {
        display: inline-block;
        vertical-align: middle;
    }
    
    .menu_overview h1 {      
        font-size: 25px;
    }

    .menu_overview h2 {
        color: darkgray;
        margin-top: -32.5px;
        font-size: 18px;
    }
    
    #content_nav,
    #our_nav {
        display: none;
    }

    #content_nav {
      padding-left: 12px;
    }

    #our_nav {
      padding-right: 20px;
    }
    
    #content_nav.open,
    #our_nav.open {
        display: block;
    }
    
    #content_nav li,
    #our_nav li {
        font-weight: bold;
        text-align: center;
        padding: 7.5px;
        position: relative;
    }

    #content_nav li:before,
    #our_nav li:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 3px; /*下線の上下位置調整*/
    display: inline-block;
    width: 125px; /*下線の幅*/
    height: 1px; /*下線の太さ*/
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%); /*位置調整*/
    background-color: darkgrey; /*下線の色*/
}
    
    #content_nav li {
        width: 135px;
        display: inline-block;
        vertical-align: bottom;
    }
    
    #our_nav li {
        /* width: fit-content; */
    }
    /*SNS関連のCSS*/
    
    .link_sns,
    .footer_sns {
        display: inline-block;
        vertical-align: top;
        width: 135px;
        margin: 16.25px 0;
    }
    
    .link_sns img,
    .footer_sns img {
        width: 41.25px;
    }
    
    .link_sns ul,
    .footer_sns ul {
        margin: 0;
        padding: 0;
    }
    
    .link_sns ul li
    {
        width: fit-content;
        font-weight: bold;
        font-size: small;
        padding: 1vh 0;
        border-bottom: 0.1em solid darkgray;
    }
    
    .menu_mail ul li {
        text-align: center;
    }
    
    .menu_twitter,
    .menu_youtube {
        padding-left: 37.5px; 
    }
    
    .sanglier {
        font-size: smaller;
    }

    @media screen and (min-width:640px){
  #gnWrapper{
  position: relative;
  top: 90px;
  left: 0;
  }
}

@media screen and (min-width:961px){
  #gnWrapper{
  position: relative;
  top: 100px;
  left: 0;
  }
}
</style>
