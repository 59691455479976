<template>
  <div class="margin">
  </div>
</template>

<script>
export default {
  name: 'Margin',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.margin {
  height: 30px;
}
@media screen and (min-width: 1441px){
  .margin{
    height: 70px;
  }
}
</style>
